import Models from "../api/Models";
import {Chip, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import {Link as RouterLink, Link} from "react-router-dom";
import React from "react";
import EditIcon from '@mui/icons-material/Edit';
import {useWebsiteStore} from "../../website/WebsiteStoreProvider";
import {keyBy} from "lodash";
import {Website} from "../../website/WebsiteModels";
import LinkIcon from '@mui/icons-material/Link';
import {useSnackbar} from "../../common/SnackbarProvider";

export function AllContentsTable(props: {
  contentsToDisplay: ReadonlyArray<Models.ContentState>,
}) {
  const {websites} = useWebsiteStore()
  const websiteConfigsByName = keyBy(websites, w => w.websiteName)

  return (
    <Table size="small">
      <TableHead>
        <AllContentHeader/>
      </TableHead>
      <TableBody>
        { props.contentsToDisplay.map(content => <AllContentRow key={content.id} content={content} website={websiteConfigsByName[content.websiteName]}/>) }
      </TableBody>
    </Table>
  );
}

function AllContentHeader() {
  return (
    <TableRow>
      <TableCell align="left"   sx={{width: "20rem"}}>Title</TableCell>
      <TableCell align="center" sx={{width:  "7rem"}}>Status</TableCell>
      <TableCell align="right"  sx={{width:  "7rem"}}>Website</TableCell>
      <TableCell align="right"  sx={{width:  "9rem"}}>Category</TableCell>
      <TableCell align="right"  sx={{width:  "9rem"}}>Last Modified</TableCell>
      <TableCell align="right"  sx={{width:  "9rem"}}>Published</TableCell>
      <TableCell align="right"  sx={{width:  "7rem"}}>Sponsored?</TableCell>
      <TableCell align="right"  sx={{width:  "7rem"}}>Featured?</TableCell>
      <TableCell align="right"  sx={{width:  "7rem"}}>Ai?</TableCell>
      <TableCell align="right"  sx={{width:  "3rem"}}/>
    </TableRow>
  );
}

function AllContentRow(props: {content: Models.ContentState, website: Website}) {
  const snackBar = useSnackbar()

  const content = props.content
  const website = props.website

  const contentUrl = `${website.websiteUrl}/${content.directory}/${content.slug}/`
  const onCopyLinkClick = async () => {
    await navigator.clipboard.writeText(contentUrl)
    snackBar.showSuccess("Link copied to clipboard")
  }

  return (
    <TableRow>
      <TableCell align="left" style={{maxWidth: "18rem"}}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton size="small" onClick={onCopyLinkClick}><LinkIcon fontSize="small"/></IconButton>
          <RouterLink style={{maxWidth: "18rem", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}} to={contentUrl} title={content.documentTitle} target="_blank">{content.documentTitle}</RouterLink>
        </Stack>
      </TableCell>
      <TableCell align="center">{contentStatusChip(content)}</TableCell>
      <TableCell align="right">{content.websiteName}</TableCell>
      <TableCell align="right">{content.categories.join(" ")}</TableCell>
      <TableCell align="right">{ageLikeString(new Date(content.lastUpdated), new Date())}</TableCell>
      <TableCell align="right">{content.publishTime ? new Date(content.publishTime).toISOString().substring(0, 10) : ""}</TableCell>
      <TableCell align="right">{content.isSponsored ? "Sponsored" : ""}</TableCell>
      <TableCell align="right">{content.isFeatured  ? "Featured" : ""}</TableCell>
      <TableCell align="right">{content.isAiArticle  ? "Ai" : ""}</TableCell>
      <TableCell align="right">
        <IconButton size="small" component={Link} to={`/contents/${content.id}/edit`}><EditIcon fontSize="small"/></IconButton>
      </TableCell>
    </TableRow>
  );
}

const DAY_IN_MS    = 1000 * 60 * 60 * 24;
const MINUTE_IN_MS = 1000 * 60;
function ageLikeString(lastUpdated: Date, currentDate: Date) {
  const previousDateTime = lastUpdated.getTime();
  const currentDateTime  = currentDate.getTime();
  const ageInDays = Math.floor((currentDateTime - previousDateTime) / DAY_IN_MS);
  if (ageInDays > 0) { // it happened more than 1 day ago
    if (ageInDays < 8) {
      return ageInDays === 1 ? 'Yesterday' : `${ageInDays} days ago`;
    } else if (ageInDays < 15) {
      return 'Last week';
    } else {
      return lastUpdated.toISOString().substring(0, 10);
    }
  } else { // it happened today
    const ageInMinutes = Math.floor((currentDateTime - previousDateTime) / MINUTE_IN_MS);
    if (ageInMinutes < 2) {
      return "Just now";
    } else if (ageInMinutes < 60) {
      return `${ageInMinutes} minutes ago`;
    } else {
      const ageInHours = Math.floor(ageInMinutes / 60);
      return ageInHours === 1 ? '1 hour ago' : `${ageInHours} hours ago`;
    }
  }
}

function contentStatusChip(content: Models.ContentState) {
  const contentStatus = contentStatusString(content);
  switch(contentStatus) {
    case "Draft":     return <Chip size="small" sx={{width: '6rem'}} label="Draft" color="warning"/>;
    case "Scheduled": return <Chip size="small" sx={{width: '6rem'}} label="Scheduled" color="info"/>;
    case "Published": return <Chip size="small" sx={{width: '6rem'}} label="Published" color="success"/>;
  }
}

function contentStatusString(content: Models.ContentState) {
  if (content.publishTime === undefined) {
    return "Draft";
  } else if (content.publishTime > Date.now()) {
    return "Scheduled";
  } else {
    return "Published";
  }
}